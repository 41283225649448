import React from 'react'

import styles from './event-type.module.css'

const EventType = ({ event }) => {
  const classes = [styles.eventType]
  let eventType
  if (event.eventTypes.includes('film')) {
    classes.push(styles.film)
    eventType = 'Film'
  } else {
    classes.push(styles.event)
    eventType = 'Event'
  }

  return <span className={classes.join(' ')}>{eventType}</span>
}

export default EventType
